import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { contactLinks, footers } from "../components/common/constants";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { SmText } from "../components/common/CustomText";

const ItemLayout = ({ title, children, opt }) => {
  return (
    <GridItem colSpan={{ base: "", md: opt ? 2 : "1" }}>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ x: [50, 0], opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <SmText lineHeight={{ base: "19px", md: "21px" }} fontWeight={700}>
          {title}
        </SmText>
      </motion.div>

      <Flex
        flexDir="column"
        gap={{ base: "20px", md: "30px" }}
        fontSize="14px"
        lineHeight="16px"
        mt={{ base: "30px", md: "40px" }}
      >
        {children}
      </Flex>
    </GridItem>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 1350px)");
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      pb="0"
      py={isMobile ? "30px" : "39px"}
      align="center"
    >
      <Box w={isMobile ? "100%" : "1350px"} px="20px">
        <Grid
          alignContent="center"
          gap={{ base: "30px", lg: "unset" }}
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(8, 1fr)" }}
        >
          <GridItem
            colSpan={{ base: "2", md: "3" }}
            w={{ base: "", md: "55%" }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ x: [-50, 0], opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <Image
                onClick={() => navigate("/")}
                mt={isMobile ? "0" : "5px"}
                cursor="pointer"
                src="/assets/logo.png"
                w="169px"
                h="40px"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ x: [-50, 0], opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <Text mt="5px" fontSize="14px" lineHeight="24px">
                Certified By: CAC; PCIDSS
              </Text>
            </motion.div>{" "}
          </GridItem>

          <ItemLayout title="Pages">
            {footers?.map((item, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0 }}
                whileInView={{ x: [50, 0], opacity: 1 }}
                transition={{ duration: 1, delay: i * 0.2 }}
              >
                <Link to={item?.path}>{item?.name}</Link>
              </motion.div>
            ))}
          </ItemLayout>

          <ItemLayout title="Service" opt>
            {[
              "Inflow Services",
              "Offshore Settlement Solutions",
              "I&E Window Access",
              "Cash Transactions",
              "Currency Liquidity Support",
            ]?.map((item, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0 }}
                whileInView={{ x: [50, 0], opacity: 1 }}
                transition={{ duration: 1, delay: i * 0.2 }}
              >
                <Text>{item}</Text>
              </motion.div>
            ))}
          </ItemLayout>

          <GridItem colSpan={2}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ x: [50, 0], opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <Text
                fontSize={{ base: "15px", md: "18px" }}
                lineHeight={{ base: "19px", md: "21px" }}
                fontWeight={700}
              >
                Contact
              </Text>
            </motion.div>

            <Flex
              flexDir="column"
              gap={{ base: "20px", md: "30px" }}
              fontSize="14px"
              lineHeight="16px"
              mt={{ base: "30px", md: "40px" }}
            >
              {contactLinks?.map((item, i) => (
                <Flex key={i} align="flex-start" gap="8px">
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ x: [50, 0], opacity: 1 }}
                    transition={{ duration: 1, delay: i * 0.2 }}
                  >
                    <Image
                      src={item?.img}
                      w={{ base: "20px", md: "14px" }}
                      objectFit="contain"
                      h="19px"
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ x: [-50, 0], opacity: 1 }}
                    transition={{ duration: 1, delay: i * 0.2 }}
                  >
                    <Text whiteSpace="pre-line">
                      {item?.url ? (
                        <a href={item?.url}>{item?.title}</a>
                      ) : (
                        item?.title
                      )}
                    </Text>
                  </motion.div>
                </Flex>
              ))}
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};

export default Footer;
