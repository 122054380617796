import { lazy } from "react";
import WithSuspense from "../components/loader/WithSuspense";
import { PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const { HOME, SERVICES, CONTACT, CAREER, ABOUT } = PATHS;

const Home = WithSuspense(lazy(() => import("../pages/Home")));
const Services = WithSuspense(lazy(() => import("../pages/Services")));
const AboutUs = WithSuspense(lazy(() => import("../pages/AboutUs")));
const Career = WithSuspense(lazy(() => import("../pages/Career")));
const Contact = WithSuspense(lazy(() => import("../pages/Contact")));

export const ROUTES = [
  { path: HOME, element: <Home /> },
  { path: SERVICES, element: <Services /> },
  { path: CAREER, element: <Career /> },
  { path: CONTACT, element: <Contact /> },
  { path: ABOUT, element: <AboutUs /> },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];
