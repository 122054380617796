import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1350px",
  "2xl": "1536px",
  "3xl": "1636px",
};

export const customTheme = extendTheme({
  fonts: {
    heading: `Gellix`,
    body: `Gellix`,
  },
  breakpoints,
  components: {
    Button: {
      variants: {
        primary: {
          border: "none",
          borderRadius: "8px",
          bgColor: "blueBg",
          fontSize: "18px",
          transition: ".3s ease-in-out",
          color: "#fff",
          fontWeight: 500,
          _hover: {
            opacity: 0.8,
          },
          _disabled: {
            _hover: {
              border: "1px solid #7B47CC",
              color: "red",
            },
          },
          _focus: {
            opacity: 0.8,
          },
          _active: {
            opacity: 0.8,
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
  },

  colors: {
    blueBg: "#0F62FE",
    blueB: "#2B5FD1",
    blue2: "#004A90",
    darkBg: "#0F172B",
    orangeBg: "#F3C948",
    semiBlue: "#F1F5FE",
  },
});
