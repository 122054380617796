import React, { useState, useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { headers } from "../components/common/constants";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Menu = ({ isOpen, onClose }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const drawerRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigation = (item, subItem) => {
    const targetPath = subItem.path.toLowerCase();
    if (item.name === "About Us") {
      if (window.location.pathname !== "/about-us") {
        navigate("/about-us", { state: { section: targetPath } });
      } else {
        scrollToSection(targetPath);
      }
    } else if (item.name === "Services") {
      if (window.location.pathname !== "/services") {
        navigate("/services", { state: { section: targetPath } });
      } else {
        scrollToSection(targetPath);
      }
    } else {
      navigate(targetPath);
      setHoverIndex(null);
    }
    onClose();
  };

  const scrollToSection = (sectionId, retryCount = 5) => {
    const element = document.getElementById(sectionId);
    if (element) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    } else if (retryCount > 0) {
      setTimeout(() => scrollToSection(sectionId, retryCount - 1), 500);
    } else {
      console.warn(`Element with ID ${sectionId} not found.`);
    }
  };

  useEffect(() => {
    if (location?.state?.section) {
      scrollToSection(location.state.section);
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setHoverIndex(null);
      }
    };
    if (isOpen) document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="top"
      style={{ height: "100vh", background: "#fff" }}
    >
      <Box mt="20px" px="15px" ref={drawerRef}>
        <Flex justifyContent="flex-end">
          <MdClose size="24px" onClick={onClose} />
        </Flex>
        {headers.map((item, i) => (
          <Box key={i}>
            <Flex
              align="center"
              px="20px"
              gap="10px"
              py="19px"
              onClick={() => setHoverIndex(i)}
            >
              {item.path ? (
                <a href={item.path}>
                  <Text
                    borderBottom={
                      location.pathname === item?.path
                        ? "3px solid #010725"
                        : ""
                    }
                  >
                    {item.name}
                  </Text>
                </a>
              ) : (
                <Text cursor="pointer">{item.name}</Text>
              )}
              {item?.sub && <IoIosArrowDown />}
            </Flex>

            {item.sub && hoverIndex === i && (
              <Box p="10px">
                {item.sub.map((subItem, subIndex) => (
                  <Box
                    key={subIndex}
                    onClick={() => handleNavigation(item, subItem)}
                    p="15px"
                    fontWeight={600}
                  >
                    {subItem.name}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        ))}
        <Button mt="40px" w="146px">
          Connect
        </Button>
      </Box>
    </Drawer>
  );
};

export default Menu;
