export const footers = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About Us",
    path: "/about-us",
  },
  {
    id: 2,
    name: "Services",
    path: "/services",
  },
];
export const headers = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About Us",
    // path: "/about-us",
    sub: [
      {
        name: "The Company",
        path: "company",
      },
      // {
      //   name: "Mission",
      //   path: "mission",
      // },
      // {
      //   name: "Vision",
      //   path: "vision",
      // },
      {
        name: "Our Team",
        path: "team",
      },
    ],
  },
  {
    id: 3,
    name: "Services",
    // path: "/services",
    sub: [
      {
        name: "Inflow Services",
        path: "inflow",
      },
      {
        name: "I&E Window Access",
        path: "ie",
      },
      {
        name: "Offshore Settlement Solutions",
        path: "offshore",
      },
      {
        name: "Cash Transactions",
        path: "cash",
      },
      {
        name: "Currency Liquidity Support",
        path: "currency",
      },
    ],
  },
  {
    id: 4,
    name: "Career",
    path: "/career",
  },
  {
    id: 4,
    name: "Contact Us",
    path: "/contact-us",
  },
];

export const contactLinks = [
  { title: "08055058367", url: "tel:08055058367", img: "/assets/phone.svg" },
  {
    title: "info@transalliancecapital.ng",
    url: "mailto:info@transalliancecapital.ng",
    img: "/assets/mail.svg",
  },
  {
    title: "No 200c Muri Okunola Street VI Lagos",
    img: "/assets/location.svg",
  },
];

export const faqs = [
  {
    question: "Do you charge any commission/fees?",
    answer: " No, we don't charge customers commission or fees.",
  },
  {
    question: "What is the maximum transaction amount I can make?",
    answer:
      "There is no fixed upper limit. However, larger transactions may require prior approval and an agreement on additional terms and conditions.",
  },
  {
    question: "Which currencies do you trade?",
    answer:
      "We facilitate trades in major currencies, including NGN, USD, EUR, GBP, JPY, AUD, and more.",
  },
  {
    question: "How secure and private are my transactions with TransCap?",
    answer:
      "We prioritize security and confidentiality, using advanced encryption and strict compliance protocols, including KYC documentation.",
  },
  {
    question: "Is TransCap licensed to operate in currency exchange?",
    answer:
      "Yes, TransCap is fully licensed and regulated for currency exchange activities. Details of our licenses can be found in the About Us section of this website.",
  },
];

export const contactIcons = [
  {
    img: "/assets/call.svg",
    altImg: "/assets/alt-call.svg",
    url: "tel:08055058367",
    title: "Call",
    text: "08055058367",
  },
  {
    img: "/assets/email.svg",
    altImg: "/assets/alt-email.svg",
    url: "mailto:info@transalliancecapital.ng",
    text: "info@transalliancecapital.ng",
    title: "Email",
  },
  {
    img: "/assets/loc.svg",
    altImg: "/assets/alt-loc.svg",
    text: "No 200c Muri Okunola Street VI Lagos",
    title: "Location",
  },
];

export const growth = [
  {
    title: "+4000 completed transactions",
    img: "/assets/rate.svg",
  },
  {
    title: "+400 satisfied customers",
    img: "/assets/247.svg",
  },
  {
    title: "99+ countries supported",
    img: "/assets/plan.svg",
  },
];

export const slides = [
  "/assets/clients/cl1.png",
  "/assets/clients/cl2.png",
  "/assets/clients/cl3.png",
  "/assets/clients/cl4.png",
  "/assets/clients/cl5.png",
  "/assets/clients/cl6.png",
  "/assets/clients/cl7.png",
  "/assets/clients/cl8.png",
  "/assets/clients/cl9.png",
  "/assets/clients/cl10.png",
  "/assets/clients/cl11.png",
  "/assets/clients/cl12.png",
  "/assets/clients/cl13.png",
];

export const mgtTeam = [
  {
    name: "Ngozi udoka-abazie",
    title: "managing director",
    img: "/assets/team/team9.png",
  },
  {
    name: "ifeoma nweje",
    title: "Regional Team Lead, east Nigeria",
    img: "/assets/team/team10.png",
  },
  {
    name: "Linda Nnado",
    title: "Regional Team Lead, South Nigeria",
    img: "/assets/team/team11.png",
  },
  {
    name: "Victoria Nwachukwu",
    title: "Regional Team Lead, East Nigeria",
    img: "/assets/team/team12.png",
  },
  {
    name: "Gift ogbonna",
    title: "Regional Team Lead, West Nigeria",
    img: "/assets/team/team13.png",
  },
  { name: "Elsie Ogo", title: "Head of Admin", img: "/assets/team/team14.png" },
  {
    name: "oluwaseun hassan",
    title: "operation officer",
    img: "/assets/team/team15.png",
  },
  {
    name: "Osaigbovo Ekhator",
    title: "Compliance Manager",
    img: "/assets/team/team16.png",
  },
];

export const teams = [
  { name: "KAMSI OKAFOR", title: "CHAIRMAN", img: "/assets/team/team1.png" },
  {
    name: "ANTHONY NWOSU",
    title: "CHIEF TREASURY & OPERATIONS OFFICER",
    img: "/assets/team/team2.png",
  },
  {
    name: "FEMI ADEDIPE",
    title: "chief Budget & FINANCE OFFICER",
    img: "/assets/team/team3.png",
  },
  {
    name: "LOVELYN MARK",
    title: "Chief Legal & Compliance Officer",
    img: "/assets/team/team6.png",
  },

  {
    name: "OLUFEMI OJO-OMONIYI",
    title: "CHIEF RISK &INTERNAL CONTROL OFFICER",
    img: "/assets/team/team4.png",
  },
  {
    name: "AFAM OBODIZIE",
    title: "CHIEF STRATEGY, INNOVATION & RESEARCH OFFICER",
    img: "/assets/team/team5.png",
  },
  {
    name: "MAYOWA IGUNBOR",
    title: "Chief People & Communication Officer",
    img: "/assets/team/team8.png",
  },
  {
    name: "ANSELM FOWEL",
    title: "Chief Technology & Innovation Officer",
    img: "/assets/team/team7.png",
  },
];

export const prog = [
  {
    title: "+4000",
    body: "completed transactions",
    img: "/assets/p1.png",
  },
  {
    title: "+200",
    body: "happy customers",
    img: "/assets/p3.png",
  },
  {
    title: "99+",
    body: "regions supported",
    img: "/assets/p3.png",
  },
];

export const missViz = [
  {
    img: "/assets/vision.png",
    body: "To be the preferred African partner in be-spoke financial service solutions",
  },
  {
    img: "/assets/mission.png",
    body: "To consistently deliver superior value to all our stakeholders locally and globally.",
  },
];

export const coreV = [
  {
    title: "People",
    body: "We believe in nurturing our people, empowering them to grow and succeed together.",
    img: "/assets/core/core1.png",
  },
  {
    title: "Innovation",
    body: "We push boundaries, embracing fresh ideas and technologies to drive progress.",
    img: "/assets/core/core2.png",
  },
  {
    title: "Integrity",
    body: "We operate with honesty and transparency, building trust with every decision.",
    img: "/assets/core/core3.png",
  },
  {
    title: "Passion",
    body: "Our energy and commitment fuel everything we do, inspiring us to go the extra mile.",
    img: "/assets/core/core4.png",
  },
  {
    title: "Excellence",
    body: "We set high standards, continuously striving to deliver exceptional results in all we do.",
    img: "/assets/core/core5.png",
  },
];

export const cult = [
  {
    title: "Professionalism",
    body: "From initial consultation to project completion, professionalism underpins everything we do.",
    img: "/assets/cult/cult1.png",
  },
  {
    title: "Extreme Ownership",
    body: "We take full responsibility for our successes and learn from every experience to improve.",
    img: "/assets/cult/cult2.png",
  },
  {
    title: "Empathy",
    body: "We prioritise understanding the unique needs of each client, looking beyond transactions to long-term impacts",
    img: "/assets/cult/cult3.png",
  },
  {
    title: "Care",
    body: "We value the ripple effects our services create, supporting client growth and peace of mind.",
    img: "/assets/cult/cult4.png",
  },
  {
    title: "Over-Communication:",
    body: "Transparency and proactive communication keep our clients informed and confident.",
    img: "/assets/cult/cult5.png",
  },
];

export const reasons = [
  {
    title: "Access to Short-Term Credit Facilities:",
    body: "Supporting our team beyond the workplace, we offer short-term credit facilities for personal use, providing an extra layer of financial flexibility.",
  },
  {
    title: "Diverse and Inclusive Culture:",
    body: "We thrive on diversity, welcoming perspectives that drive innovation and foster a truly inclusive workplace.",
  },
  {
    title: "Comprehensive Health Plans:",
    body: "Health and well-being are paramount. Our health plans are designed to provide peace of mind and care for you and your family.",
  },
  {
    title: "Opportunities for Professional Development:",
    body: "Growth is at the heart of our culture. Whether through training, mentorship, or skill-building programs, we support your path to advancement.",
  },
  {
    title: "Competitive Salaries and Benefits:",
    body: "Rewarding work deserves rewarding compensation. We ensure that our team receives competitive pay and benefits that reflect their value.",
  },
];

export const services = [
  { title: "Offshore Settlement Solution", img: "/assets/serv/serv1.png" },
  { title: "I&E Window Access", img: "/assets/serv/serv2.png" },
  { title: "Inflow Services", img: "/assets/serv/serv3.png" },
  { title: "Cash Transactions", img: "/assets/serv/serv4.png" },
];

export const testi = [
  "Transalliance Capital has been a game-changer for our business. Their bespoke solutions have enabled us to optimize our foreign exchange operations, saving us both time and money. I'm confident that they will continue to be a key partner in our future growth.",
  "Dealing with foreign exchange used to be a major headache for our company. However, since partnering with Transalliance Capital, the process has become seamless. Their team is always responsive and goes the extra mile to ensure our needs are met. I'm truly impressed with their level of service",
];

export const homeTabs = [
  {
    id: 1,
    imageSrc: "/assets/hero.png",
    mt: "46px",
    path: "/services",
    btnText: "Read More",
    text: "Experience unwavering support, always,",
  },
  {
    id: 2,
    imageSrc: "/assets/home/home1.png",
    mt: "84px",
    path: "/services",
    btnText: "Read More",
    text: "Step into our world of exchange",
  },
  {
    id: 3,
    imageSrc: "/assets/home/home2.png",
    mt: "98px",
    path: "/contact-us",
    btnText: "Contact Us",
    text: "We're building for the future",
  },
  {
    id: 4,
    mt: "115px",
    imageSrc: "/assets/home/home3.png",
    text: "Now is the time",
    path: "/career",
    btnText: "Join Us",
  },
];
